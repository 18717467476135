import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Action, Getter, namespace } from "vuex-class";
import { APPOINTMENT_NAMESPACE, appointment } from "@/_store/appointment";
import PropertyCardComponent from "@/components/property-card/PropertyCard.vue";
import CalendarComponent from "./calendar/CalendarComponent.vue";
import {
  sendMessage,
  getHeightDocument,
  getAddressProperty,
  getBoolean,
  getAddressProject,
  retry,
} from "@/_utils";
import { IConfigAppointmentModule } from "@/_store/types";
import { IProperty } from "@/model/property.model";
import { IProject } from "@/model/project.model";

const appointmentModule = namespace(APPOINTMENT_NAMESPACE);

@Component({
  components: {
    PropertyCardComponent,
    CalendarComponent,
  },
})
export default class OpenAppointment extends Vue {
  @appointmentModule.Getter getConfig!: IConfigAppointmentModule;
  @appointmentModule.Getter getError!: { message: string; times: number };

  @appointmentModule.Action storeError!: (payload: {
    message: string;
    times: number;
  }) => void;

  @Action("getAppointmentByObjectId", { namespace: APPOINTMENT_NAMESPACE })
  private getAppointmentByObjectId!: (criteria: {
    objectId: string;
    key: string;
    IsProject: boolean;
  }) => Promise<void>;

  @Action("checkIfUserRegistered", { namespace: APPOINTMENT_NAMESPACE })
  private checkIfUserRegistered!: (criteria: {
    objectId: string;
    key: string;
  }) => Promise<void>;

  @Action("getProperty", { namespace: APPOINTMENT_NAMESPACE })
  private getPropertyAction!: (criteria: {
    objectId: string;
    key: string;
  }) => Promise<void>;

  @Getter("getProperty", { namespace: APPOINTMENT_NAMESPACE })
  private getProperty!: IProperty;

  @Action("getProject", { namespace: APPOINTMENT_NAMESPACE })
  private getProjectAction!: (criteria: {
    objectId: string;
    key: string;
  }) => Promise<void>;

  @Getter("getProject", { namespace: APPOINTMENT_NAMESPACE })
  private getProject!: IProject;

  @Getter("getStatusLoading", { namespace: APPOINTMENT_NAMESPACE })
  private getStatusLoading!: boolean;

  get isProject() {
    return getBoolean(this.getConfig.isProject);
  }

  public async mounted() {
    if (!!this.getConfig.key && !!this.getConfig.objectId) this.handleGetData();
    this.handleSendHeight();
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }

  @Watch("getConfig", { deep: true })
  async handleWatchData(
    newval?: IConfigAppointmentModule,
    oldval?: IConfigAppointmentModule
  ) {
    if (!!newval && !!newval.key && !!newval.objectId) {
      this.handleGetData();
    }
    this.handleSendHeight();
  }

  async handleGetData(
    newval?: IConfigAppointmentModule,
    oldval?: IConfigAppointmentModule
  ) {
    if (this.isProject === true) {
      await this.getDataProject();
    } else {
      await this.getData();
    }
    this.handleSendHeight();
  }

  async getData() {
    try {
      const promises = [];
      //promises.push(retry(10, 1000, () => this.checkIfUserRegistered({ objectId: this.getConfig.objectId, key: this.getConfig.key })));
      promises.push(
        retry(10, 1000, () =>
          this.getPropertyAction({
            objectId: this.getConfig.objectId,
            key: this.getConfig.key,
          })
        )
      );
      promises.push(
        retry(10, 1000, () =>
          this.getAppointmentByObjectId({
            objectId: this.getConfig.objectId,
            key: this.getConfig.key,
            IsProject: false,
          })
        )
      );
      await Promise.all(promises).then((values: any) => {
        if (values[1].data.GetAppointmentObjectJsonResult.length == 0) {
          this.handleEmptyAppointments();
        }
      });

      sendMessage({ type: "load-data", mess: "load property is success" });
    } catch (err) {
      console.log(err);
    }
  }

  async getDataProject() {
    try {
      const promises = [];
      promises.push(
        retry(10, 1000, () =>
          this.getProjectAction({
            objectId: this.getConfig.objectId,
            key: this.getConfig.key,
          })
        )
      );
      promises.push(
        retry(10, 1000, () =>
          this.getAppointmentByObjectId({
            objectId: this.getConfig.objectId,
            key: this.getConfig.key,
            IsProject: true,
          })
        )
      );
      await Promise.all(promises);
      sendMessage({ type: "load-data", mess: "load property is success" });
    } catch (err) {
      console.log(err);
    }
  }

  get appHeight() {
    const appEl = document.getElementById("app");
    if (appEl && this.getConfig.isMobile === "false") {
      return appEl.offsetHeight;
    }
    return 0;
  }

  @Watch("getConfig", { deep: true })
  handleChangeAppHeight() {
    this.handleSendHeight();
  }

  handleSendHeight() {
    if (this.getConfig.isMobile === "false") {
      const appEl = document.getElementById("app");
      if (appEl)
        sendMessage({
          type: "resize-modal",
          mess: appEl.offsetHeight + 80 + "px",
        });
    }
  }

  get buttonBackColor() {
    return "#" + this.getConfig.buttonBackColor || "";
  }

  get buttonFontColor() {
    return "#" + this.getConfig.buttonFontColor || "";
  }

  get statusLoading() {
    return this.getStatusLoading;
  }

  get propertyPrice() {
    let price = "";

    if (this.isProject && this.getProject) {
      if (this.getProject.PriceMin || this.getProject.PriceMax) {
        price =
          this.getProject.PriceMin === this.getProject.PriceMax
            ? `${this.getProject.PriceMin || this.getProject.PriceMax} ‎€`
            : `${this.$t("FROM")} <b>${
                this.getProject.PriceMin
              } €</b> ${this.$t("TO")} <b>${this.getProject.PriceMax} €</b>`;
        return price;
      }
    }

    if (!this.isProject && this.getProperty) {
      price = this.getProperty.Price!.toString();
      price = this.getProperty.ChargesRenter
        ? `<b>${this.getProperty.Price} €</b> + <b>${
            this.getProperty.ChargesRenter
          } €</b> ${this.$t("CHARGES")}`
        : `${price} ‎€`;
      return price;
    }
    return "";
  }

  get propertyPicture() {
    if (this.isProject && this.getProject) {
      if (this.getProject.LargePicture) {
        return this.getProject.LargePicture;
      }
      if (this.getProject.LargePictureItem) {
        return this.getProject.LargePictureItem.Url;
      }
      if (this.getProject.MediumPictureItem) {
        return this.getProject.MediumPictureItem.Url;
      }
      if (this.getProject.SmallPictureItem) {
        return this.getProject.SmallPictureItem.Url;
      }
    }

    if (!this.isProject && this.getProperty) {
      if (this.getProperty.LargePictureItem) {
        return this.getProperty.LargePictureItem.Url;
      }
      if (this.getProperty.MediumPictureItem) {
        return this.getProperty.MediumPictureItem.Url;
      }
      if (this.getProperty.SmallPictureItem) {
        return this.getProperty.SmallPictureItem.Url;
      }
    }
    return "";
  }

  get propertyAddress() {
    if (this.isProject && this.getProject) {
      return getAddressProject(this.getProject);
    }

    if (!this.isProject && this.getProperty) {
      return this.getProperty ? getAddressProperty(this.getProperty) : "";
    }

    return "";
  }

  handleOpenLinkFromIframe() {
    switch (this.getConfig.language) {
      case "nl":
        sendMessage({
          type: "open-link",
          mess: "www.omnicasa.com/online-booking",
        });
        break;
      default:
        sendMessage({
          type: "open-link",
          mess: "www.omnicasa.com/rendez-vous-en-ligne",
        });
        break;
    }
  }

  handleEmptyAppointments() {
    if (
      (this.getConfig.showWhenFull && this.getConfig.showWhenFull === "yes") ||
      (this.getConfig.showWhenNoAppointments &&
        this.getConfig.showWhenNoAppointments === "yes")
    ) {
      if (this.isProject) {
        this.$router.replace({
          name: "contact-appointment-project",
        });
      } else {
        this.$router.replace({
          name: "contact-appointment",
        });
      }
    }
  }
}
