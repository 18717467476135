import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class PropertyCard extends Vue {
  @Prop() private classString!: string;
  @Prop() private title!: string;
  @Prop() private picture!: string;
  @Prop() private description!: string;
  @Prop() private hideButton!: boolean;
  @Prop() private disableConfirmButton!: boolean;
  @Prop() private propertyConfirmClick!: () => void;
}
